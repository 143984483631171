<template>
  <a-card title="我的项目" :bodyStyle="{height: '410px'}">
    <a-table
        :columns="columns"
        :data-source="list"
        :loading="tableLoading"
        :pagination="pagination"
        rowKey="id"
        bordered
        @change="pageChange"
    >
      <template slot="projectName" slot-scope="text">
        <span class="text-underline cursor" @click="$util.checkUrl(authority.viewProjectDetail, `/project/projectDetail?id=${item.id}&title=${item.projectName}`)">{{ text }}</span>
      </template>
      <template slot="projectStatus" slot-scope="text">
        <span v-if="text === 1">洽谈中</span>
        <span v-if="text === 2">规划中</span>
        <span v-if="text === 3">实施中</span>
        <span v-if="text === 4">验收中</span>
        <span v-if="text === 5">已完成</span>
        <span v-if="text === 6">已取消</span>
        <span v-if="text === 7">已搁置</span>
      </template>
      <template slot="projectLevel" slot-scope="text">
        <a-rate :value="text || 0" :count="text || 0" disabled />
      </template>
      <template slot="projectProgress" slot-scope="text">
        <a-progress :percent="text || 0" />
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { getCenterProject } from '../../api/center/center'
import { mapState } from 'vuex'

export default {
  name: 'centerProject',
  data () {
    return {
      columns: [
        {
          title: '项目名称',
          dataIndex: 'projectName',
          scopedSlots: { customRender: 'projectName' }
        },
        {
          title: '项目状态',
          dataIndex: 'projectStatus',
          scopedSlots: { customRender: 'projectStatus' }
        },
        {
          title: '项目等级',
          dataIndex: 'projectLevel',
          scopedSlots: { customRender: 'projectLevel' }
        },
        {
          title: '我的项目职位',
          dataIndex: 'myProjectPostionName'
        },
        {
          title: '项目负责人',
          dataIndex: 'projectLeaderName'
        },
        {
          title: '项目进度',
          dataIndex: 'projectProgress',
          scopedSlots: { customRender: 'projectProgress' }
        },
        {
          title: '启动日期',
          dataIndex: 'startDate'
        },
        {
          title: '计划结束日期',
          dataIndex: 'planEndDate'
        },
        {
          title: '实际结束日期',
          dataIndex: 'actualEndDate'
        },
        {
          title: '客户名称',
          dataIndex: 'customerName'
        }
      ],
      list: [],
      filterForm: {
        pageNum: 1,
        pageSize: 5
      },
      pagination: {
        total: 0,
        pageSize: 5
      },
      tableLoading: true,
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true
      getCenterProject(this.filterForm).then(res => {
        this.list = res.data.list
        this.pagination.total = res.data.total
        this.tableLoading = false
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
